import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom'
import { getEssentialClassics} from '../../actions/PlaylistActions'
import { useDispatch, useSelector } from 'react-redux'
import ArrowRight from '../icons/Arrow_right.svg'
import Slider from '../slides/Slider';

const EssentialClassics = (props) => {

    const dispatch = useDispatch()

    const navigate = useNavigate()

    const { essentialClassics } = useSelector(state => state.essentialClassics)



    useEffect(()=>{

        dispatch(getEssentialClassics())

    },[dispatch])


    const itemClickHandler = (item) => {
        localStorage.setItem('playlist_id', 251)
        localStorage.setItem('video_id', item.id)
        localStorage.setItem('group_id', 10)
        if(props.user) {
          navigate(encodeURI(`/home?g=${10}&p=${251}&v=${item.id}`))
        } else {
          navigate('/login')
        }
      }

      const viewAllHandler = () => {
        if(props.user){
            navigate(encodeURI(`/playlist/251/Essential Classics`))
        } else {
            navigate('/login')
        }
      }


    return (
        <div className="col-12" style = {{marginTop:"2.5vw"}}>
            <div className="col-12 ps-5 d-flex align-items-center pb-2" style = {{marginBottom:"0.5vw", fontSize:"1.25rem", color:'white'}}>
                <span className="text-white fw-bold fs-7 ps-4 pe-3">New Audio / Best on YouTube</span>
                <img src = {ArrowRight} onClick={()=>viewAllHandler()} alt = '' style={{cursor:"pointer"}}/>
            </div>
            <div className='position-relative' id = "carouselListWrapper">
                {
                    essentialClassics && essentialClassics.videos && essentialClassics.videos.length ?
                    <Slider
                        num = {5}
                        type = {2}
                        showTitle = {false}
                        tvData={essentialClassics.videos}
                        slideClickHandler={itemClickHandler}
                    /> : ""
                }
            </div>
        </div>
    );
};

export default EssentialClassics;