import React, {useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getGroupPlaylists } from '../../actions/PlaylistActions'
import { useParams } from 'react-router-dom'
import arrowLeft from '../icons/Arrow_left.svg'
import { useNavigate } from 'react-router-dom';

const GroupPage = () => {

    const {group_id, group_title} = useParams()

    const dispatch = useDispatch()

    const navigate = useNavigate()

    const playlistList = useSelector(state => state.groupPlaylists)
  
    const { loading, groupPlaylists} = playlistList
  
    useEffect(()=>{
  
      dispatch(getGroupPlaylists(group_id))
  
    },[dispatch, group_id])
    

    return (
        <>
            <div className = "w-100 min-vh-100 bg-bluedark d-flex flex-column align-items-center">
                    <div className = "w-100 d-flex flex-column align-items-center pb-5">
                    { 
                        loading ? <div className = "text-info col-12 col-lg-10" >Loading...</div> :
                        <>
                            <div className = 'w-100 d-flex justify-content-center pt-5 pb-2'>
                                <div className = 'col-12 px-5 col-lg-10 d-flex'>
                                    <img 
                                        src = {arrowLeft} 
                                        role = 'button'
                                        onClick={()=>navigate(-1)}
                                        alt = ''/>
                                    <div className='ps-3 text-white fs-7 fw-bold'>{group_title}</div>
                                </div>
                            </div>

                            <div className='col-12 col-lg-10 px-4 d-flex flex-row flex-wrap'>
                                {
                                    groupPlaylists && groupPlaylists.length ? 
                                    groupPlaylists.map((playlist)=>(
                                        <div className='p-3 rounded'>
                                            <img 
                                                src = {`https://img.cugate.com/?o=CUTV_PLAYLIST&i=${playlist.id}&s=300`} 
                                                alt = ''
                                                className='rounded'
                                                role = 'button'
                                                onClick={()=>navigate(`/home?v=${playlist.video_id}&p=${playlist.id}&g=${group_id}`)}
                                            />
                                        </div>
                                    )) : ""
                                }
                            </div>
                        </>
                    }
                    </div>  
                    <div className='w-100 bg-primary' style={{height:'100px'}}></div>
                    
            </div>
        </>
    );
};

export default GroupPage;