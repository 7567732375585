import React, {useState, useEffect} from 'react'
import GroupHeader from './GroupHeader';
import PlaylistGroup from './PlaylistGroup';
import { useSelector } from 'react-redux'
import arrowRight from '../icons/Arrow_right.svg'
import { useNavigate } from 'react-router-dom';

const GroupsComponent = (props) =>{

    const navigate = useNavigate()

    let group_id = parseInt(props.searchParams.get('g')) ? parseInt(props.searchParams.get('g')) : localStorage.getItem('group_id') ? localStorage.getItem('group_id') : 4

    const groupList = useSelector(state => state.playlistGroups)

    const { playlistGroups} = groupList

    const [activeGroup, setActiveGroup] = useState()

    useEffect(()=>{
        if(playlistGroups && playlistGroups.length){
            let group = playlistGroups.find(g=>g.id===group_id)
            setActiveGroup(group)
        }
    },[playlistGroups])




    return(
        <div className = 'container-fluid bg-bluedark pt-5' style={{backgroundColor: '#07294c'}}>
            <div className="col-12 pt-3 d-flex flex-column justify-content-center align-items-center">
                {
                    activeGroup &&
                    <GroupHeader
                        activeGroup = {activeGroup}
                        setActiveGroup = {setActiveGroup}
                        searchParams = {props.searchParams}
                        setSearchParams = {props.setSearchParams}
                        groups = {playlistGroups}
                    />
                }
                </div>
                {/* {
                    activeGroup &&
                    <div className='w-100 d-flex justify-content-center py-3'>
                        <div className='col-11 d-flex align-items-center'>
                            <div className='fw-bold pe-2 fs-7 text-white'>{activeGroup.title}</div>
                            <img 
                                src = {arrowRight} 
                                alt='' 
                                onClick={()=>navigate(`/group/${activeGroup.id}/${activeGroup.title}`)}
                                role = 'button'
                            />
                        </div>
                    </div>
                } */}

                {
                    activeGroup &&
                    <PlaylistGroup
                        activeGroup = {activeGroup}
                        setActiveGroup = {setActiveGroup}
                        displayCount={props.displayCount}
                        sCWHeight={props.sCWHeight}
                        slideClickHandler={props.slideClickHandler}
                        showAllPath={props.showAllPath}
                    />
                }

        </div>
    )
}

export default GroupsComponent;