import React from 'react';
// import LandingHeader from './LandingHeader'
import LandingFooter from './LandingFooter';
import Banner from './Banner';
import './LandingStyles.css'
import { useSelector} from 'react-redux';
import BottomComponent from './BottomComponent';
import Concerts from './Concerts';
import NewVideos from './NewVideos';
import Genres from './Genres';
import Categories from './Categories';
import Groups from './Groups';
import EssentialClassics from './EssentialClassics';

const LandingPage = () => {

    const {userLoading, user} = useSelector(state=>state.user)

    return (
        <div>
            <div id = 'landing_body'>
                <Banner
                    user = {user}
                />
                <div style={{ marginTop:'-7vw', zIndex:2, position:'relative'}}>
                    <Categories/>

                    <NewVideos
                        title = {'New Videos'}
                    />
                    <EssentialClassics
                        user = {user}
                    />
                    <Concerts
                        user = {user}
                        slidesToShow = {1}
                    />

                    <Genres
                        user = {user}
                    />
                    <Groups
                        user = {user}
                    />
                    <BottomComponent
                        user = {user}
                    />
                    <LandingFooter/>
                </div>
            </div>
        </div>
        
    );
}

export default LandingPage;