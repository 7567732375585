import React, {useState} from 'react';
import Image from '../icons/Bottom_Image.png'
import Activity from './Activity';
import Mood from './Mood';

function BottomComponent(props) {

    return (
        <div className='w-100 p-2 p-lg-5 position-relative'>
            <img 
                className='w-100'
                src = {Image} 
                alt = ''/>
            <div className = 'position-absolute bottom-0 p-2 p-lg-5'>
                <div className='pb-5'>
                    <div className = 'text-white text-wrap fw-bold bottom_text'>
                        We will advise you what to see 
                    </div>
                    <div className = 'text-white text-wrap fw-bold bottom_text'>
                        and listen to when you want...
                    </div>
                </div>
                <div className='d-flex py-2 py-lg-5 col-10 col-lg-9' style={{gap:'20px'}} >
                    <Activity
                        user = {props.user}
                    />
                    <Mood
                        user = {props.user}
                    />
                </div>
            </div>
        </div>
    );
}

export default BottomComponent;