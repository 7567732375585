import { legacy_createStore as createStore, combineReducers, applyMiddleware, compose } from 'redux'
import {thunk} from 'redux-thunk'
import { adminVideosReducer, activeVideoReducer, videoClicksReducer, videoInfoReducer, videosForBannerReducer } from './reducers/videoReducers'
import { genresReducer, moodsReducer, artistsReducer, searchResultsReducer, composersReducer, activitiesReducer, tagsReducer, videogenresReducer, activeGenresReducer, categoriesReducer, categoryVideosReducer, filterReducer, searchSuggestionsReducer } from './reducers/filterReducers'
import { userPlaylistsReducer, downloadReducer, playlistGroupsReducer, groupPlaylistsReducer, essentialClassicsReducer, allPlaylistsReducer, concertsAndPerformancesReducer, latestPlaylistsReducer  } from './reducers/PlaylistReducers'
import { userReducer, checkEmailReducer, resetPasswordReducer, resetPasswordConfirmReducer } from './reducers/AuthReducers'
import { analyzeInfoReducer } from './reducers/AnalyzeReducers'
import { adminGenresReducer, adminMoodsReducer, adminVideogenresReducer, adminTagsReducer, adminAlbumsReducer, adminTracksReducer } from './reducers/AdminFilterReducers'
import { adminGroupsReducer, groupInfoReducer } from './reducers/GroupsReducers'
import { adminPlaylistsReducer, playlistsSearchSuggestionsReducer, playlistInfoReducer } from './reducers/AdminPlaylistReducers'
import { bannerVideosReducer, bannerPlaylistsReducer, bannerThemesReducer } from './reducers/BannerReducers'

const reducer = combineReducers({
    activeVideo : activeVideoReducer,
    videosForBanner: videosForBannerReducer,
    videoClicks : videoClicksReducer,
    genres : genresReducer,
    moods: moodsReducer,
    artists: artistsReducer,
    composers: composersReducer,
    tags: tagsReducer,
    videogenres: videogenresReducer,
    groupPlaylists: groupPlaylistsReducer,
    playlistGroups: playlistGroupsReducer,
    user: userReducer,
    allPlaylists: allPlaylistsReducer,
    userPlaylists: userPlaylistsReducer,
    filterResults: filterReducer,
    searchSuggestions: searchSuggestionsReducer,
    searchResults : searchResultsReducer,
    file : downloadReducer,
    checked_email: checkEmailReducer,
    reset_password: resetPasswordReducer,
    reset_password_confirm: resetPasswordConfirmReducer,
    analyze_info: analyzeInfoReducer,
    adminVideos: adminVideosReducer,
    adminGenres : adminGenresReducer,
    adminMoods : adminMoodsReducer,
    adminVideogenres : adminVideogenresReducer,
    adminTags : adminTagsReducer,
    adminAlbums : adminAlbumsReducer,
    adminTracks : adminTracksReducer,
    adminGroups: adminGroupsReducer,
    adminPlaylists: adminPlaylistsReducer,
    playlistSearchSuggestions: playlistsSearchSuggestionsReducer,
    playlistInfo: playlistInfoReducer,
    videoInfo : videoInfoReducer,
    bannerVideos: bannerVideosReducer,
    bannerPlaylists: bannerPlaylistsReducer,
    bannerThemes: bannerThemesReducer,
    groupInfo: groupInfoReducer,
    concertsAndPerformances: concertsAndPerformancesReducer,
    activeGenres: activeGenresReducer,
    categories: categoriesReducer,
    latestPlaylists: latestPlaylistsReducer,
    essentialClassics: essentialClassicsReducer,
    categoryVideos: categoryVideosReducer,
    activities: activitiesReducer
})

const initialState = {}

const middleware = [thunk]

const store = createStore(reducer, initialState, 
    compose(applyMiddleware(...middleware), 
    typeof window.__REDUX_DEVTOOLS_EXTENSION__ === "undefined"
        ? a => a
        : window.__REDUX_DEVTOOLS_EXTENSION__ &&
            window.__REDUX_DEVTOOLS_EXTENSION__()))

export default store