import React, {useState, useEffect } from 'react';
import nextArrow from '../icons/nextArrow.svg'
import prevArrow from '../icons/prevArrow.svg'
import Slider from 'react-slick'
import { getLatestPlaylists } from '../../actions/PlaylistActions'
import { useDispatch, useSelector } from 'react-redux';
import ArrowRight from '../icons/Arrow_right.svg'
import useWindowDimentions from '../useWindowDimentions';
import SlideWithVideo from './SlideWithVideo';


function NewVideos(props) {

    const [newVideoRef, setNewVideosRef] = useState()

    const [activeSlide, setActiveSlide] = useState(0); 

    const [centerPadding, setCenterPadding] = useState()

    const [hover, setHover] = useState()

    const dispatch = useDispatch()

    const {width, height} = useWindowDimentions()

    const { error, latestPlaylists} = useSelector(state => state.latestPlaylists)
  
    useEffect(()=>{
        if(width > height){
            setCenterPadding(400)
        } else {
            setCenterPadding(100)
        }
    },[width, height])
  
    useEffect(()=>{ 
  
      dispatch(getLatestPlaylists())
  
    },[dispatch])

    const settings = {
        className:'center',
        centerMode: true,
        dots: false,
        infinite: true,
        arrows: false,
        speed: 500,
        slidesToScroll: 1,
        slidesToShow: 2,
        centerPadding: `${centerPadding}px`,
        inisialSlide: 0,
        mobileFirst:true,
        afterChange: (current) => setActiveSlide(current),
        responsive: [
            {
              breakpoint: 0,
              settings: {
                slidesToShow:1,
                slidesToScroll: 1,
                initialSlide: 0,
              }
            },
            {
              breakpoint: 800,
              settings: {
                slidesToShow:1,
                slidesToScroll: 1,
                initialSlide: 0, 
              }
            },
            {
              breakpoint: 1120,
              settings: {
                slidesToShow:1,
                slidesToScroll: 1,
                initialSlide: 0,
              }
            },
            {
                breakpoint: 1400,
                settings: {
                    slidesToShow:1,
                    slidesToScroll: 1,
                    initialSlide: 0,
                }
              },
              {
                breakpoint: 9000,
                settings: {
                    slidesToShow:1,
                    slidesToScroll: 1,
                    initialSlide: 0,
                }
              }
          ]
      };


    return (
        <div className="col-12">
            <div className="col-12 ps-5 d-flex pb-2 align-items-center" style = {{marginBottom:"0.5vw", fontSize:"1.25rem", color:'white'}}>
                <span className="text-white fw-bold fs-7 ps-4 pe-3">New  Playlists</span>
                <img src = {ArrowRight} alt = '' style={{cursor:"pointer"}}/>
            </div>
            <div className='position-relative'>
                <div 
                    className= "position-absolute d-flex align-items-center justify-content-center visible"
                    id="button-prev"
                    role = 'button'
                    onClick={newVideoRef?.slickPrev}>
                        <img src = {prevArrow} alt = ''
                        />
                </div>
                <Slider {...settings} ref={setNewVideosRef} slickGoTo>
                    { latestPlaylists && latestPlaylists.length ?
                        latestPlaylists.map((item, index) => (
                            <SlideWithVideo
                                item = {item}
                                key = {index}
                                index = {index}
                                hover = {hover}
                                setHover = {setHover}
                                activeSlide = {activeSlide}
                            />
                        )) : ''
                    }
                </Slider>

                <div  className="position-absolute d-flex align-items-center justify-content-center visible"
                    role = 'button'
                    id="button-next" 
                    onClick={newVideoRef?.slickNext}>
                    <img src={nextArrow} 
                        alt = ''       
                    />
                </div> 
            </div>
        </div>
    );
}

export default NewVideos;