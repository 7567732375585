import React, {useEffect} from 'react';
import UserContent from './app-main-content/UserContent';
import AdminContent from './adminContent/AdminContent';
import AuthContent from './app-main-content/AuthContent';
import { checkAuthenticated, load_user } from './actions/AuthActions';
import { useDispatch } from 'react-redux'

const Content = (props) => {

    const dispatch = useDispatch()

    useEffect(()=>{
        dispatch(checkAuthenticated())
        dispatch(load_user())
      },[dispatch])

  return (
    <>
      <UserContent/>
      <AuthContent/>
      <AdminContent/>
    </>
  );
}

export default Content;