import React, {useRef} from 'react';
import ReactPlayer from 'react-player/lazy'
import { useNavigate } from 'react-router-dom';

const SlideWithVideo = (props) => {

    const navigate = useNavigate()

    const activePlaylistRef = useRef()

    const clickHandler = (item) => {
        localStorage.setItem('playlist_id', item.id)
        localStorage.setItem('video_id', item.video.id)
        localStorage.setItem('group_id', item.group_id)
        if(props.user) {
          navigate(encodeURI(`/home?g=${item.group_id}&p=${item.id}&v=${item.video.id}`))
        } else {
          navigate('/login')
        }
    }

    return (
        <div className='px-2 h-100' key={props.index} 
            onClick = {()=>{clickHandler(props.item)}}
            onMouseMove={()=>props.setHover(props.item.id)}
            onMouseLeave={()=>props.setHover()}>
            <div style = {{cursor:"pointer", height:'100%'}} >
                { 
                    props.hover && props.hover === props.item.id &&  props.item.video.scripted_bunny_path?
                    <ReactPlayer
                        ref = {activePlaylistRef }
                        className='react-player'
                        // controls
                        url = {props.item.video.scripted_bunny_path}
                        // onReady={()=>readyHandler()}
                        playing = {true}
                        muted = {true}
                        // light = {`https://img.cugate.com/?o=CUTV_PLAYLIST&i=${props.item.id}&s=300`}
                        config={{
                        file: { 
                            attributes: {
                            controlsList: 'nodownload noprops.fullscreen'
                            }
                        }
                        }}
                        width="100%"
                        height='100%'
                    />   :
                    <img src = {`https://img.cugate.com/?o=CUTV_PLAYLIST&i=${props.item.id}&s=600`}
                        alt = "aaaaaa" 
                        className='rounded'
                        style = {
                            props.index === props.activeSlide ? {
                            objectFit: 'cover',
                            width:"99%", 
                            height: `100%`,
                        } :
                        {
                            objectFit: 'cover',
                            width:"99%", 
                            height: `100%`,
                            opacity:"0.4"
                        }}
                        onError = {({ currentTarget }) => {
                            currentTarget.onerror = null // prevents looping
                            // currentTarget.src=Default
                            }}    
                    />  
                }
            </div>
    </div>
    );
};

export default SlideWithVideo;