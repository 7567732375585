import React from 'react';
import {Routes, Route} from 'react-router';
import Register from './user/Register'
import Login from './user/Login'
import ResetPassword from './user/ResetPassword'
import ResetPasswordConfirm from './user/ResetPasswordConfirm';
import UserActivate from './user/UserActivate';

const AuthContent = () => {
    return (
        <>
            <Routes>
                <Route path = '/register' element = {
                        <Register
                        />
                    }             
                />
                <Route path = '/login' element = {
                    <Login
                    />
                }
                />                       
                <Route path = '/reset_password' element = {
                    <ResetPassword
                    />
                }
                />
                <Route path = '/password-reset/:uid/:token' element = {
                    <ResetPasswordConfirm
                    />
                }
                />
                <Route path = '/activate/:uid/:token' element = {
                    <UserActivate
                    />
                }
                />
            </Routes>
        </>
    );
};

export default AuthContent;