import React, {useEffect, useState} from 'react';
import Slider from 'react-slick'
import {useNavigate} from 'react-router-dom'
import nextArrow from '../icons/nextArrow.svg'
import prevArrow from '../icons/prevArrow.svg'
import { getConcertsAndPerformances} from '../../actions/PlaylistActions'
import { useDispatch, useSelector } from 'react-redux'
import ArrowRight from '../icons/Arrow_right.svg'
import SingleSlide from './SingleSlide';

function Concerts(props) {


    const [loungeRef, setLoungeRef] = useState()

    const dispatch = useDispatch()

    const navigate = useNavigate()

    const [activeSlide, setActiveSlide] = useState()

    const playlistList = useSelector(state => state.concertsAndPerformances)

    const { error, concertsAndPerformances} = playlistList


    useEffect(()=>{

        dispatch(getConcertsAndPerformances())

    },[dispatch])


    const itemClickHandler = (item) => {
        localStorage.setItem('playlist_id', item.id)
        localStorage.setItem('video_id', item.video.id)
        localStorage.setItem('group_id', 5)
        if(props.user) {
          navigate(encodeURI(`/home?g=${5}&p=${item.id}&v=${item.video.id}`))
        } else {
          navigate('/login')
        }
      }

      useEffect(()=>{
        props.playlists && props.playlists.length && props.slideRef?.slickGoTo(0)
    },[props.playlists])



    const settings = {
        // className:'center',
        // centerMode: true,
        dots: false,
        // infinite: true,
        arrows: false,
        speed: 500,
        slidesToShow: 3, // Show multiple slides but without centering the first
        slidesToScroll: 2,
        inisialSlide: 1,
        mobileFirst:true,
        responsive: [
            {
              breakpoint: 0,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                initialSlide: 1,
              }
            },
            {
              breakpoint: 800,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                initialSlide: 1, 
              }
            },
            {
              breakpoint: 1120,
              settings: {
                slidesToShow: 4 , // Show multiple slides but without centering the first
                slidesToScroll: 2,
                initialSlide: 0,
              }
            },
            {
                breakpoint: 1400,
                settings: {
                  slidesToShow: 5,
                  slidesToScroll: 3, 
                  initialSlide: 0,

                }
              },
              {
                breakpoint: 9000,
                settings: {
                  slidesToShow: 5,
                  slidesToScroll: 3, 
                  initialSlide: 0,
                }
              }
          ]
      };


      const viewAllHandler = () => {
        if(
            props.user){
          navigate(encodeURI(`/group/5/Concerts and Performances`))
        } else {
          navigate('/login')
        }
      }


    return (
        <div className="col-12" style = {{marginTop:"2.5vw"}}>
            <div className="col-12 ps-5 d-flex align-items-center pb-2" style = {{ fontSize:"1.25rem", color:'white'}}>
                <span className="text-white fw-bold fs-7 ps-4 pe-3">New Concerts</span>
                <img src = {ArrowRight} onClick={()=>viewAllHandler()} alt = '' style={{cursor:"pointer"}}/>
            </div>
            <div className='position-relative' id = "carouselListWrapper">
                <div 
                    className= "position-absolute d-flex align-items-center justify-content-center visible"
                    id="button-prev"
                    role = 'button'
                    onClick={loungeRef?.slickPrev}>
                        <img src = {prevArrow} alt = ''
                        />
                </div>
                <Slider {...settings} ref={setLoungeRef} slickGoTo>
                    { concertsAndPerformances && concertsAndPerformances.length ?
                        concertsAndPerformances.map((item, index) => (
                            <SingleSlide
                                item = {item}
                                index = {index}
                                key = {index}
                                setActiveSlide = {setActiveSlide}
                                activeSlide = {activeSlide}
                                itemClickHandler = {itemClickHandler}
                            />
                        )) : ''
                    }
                </Slider>

                <div  className="position-absolute d-flex align-items-center justify-content-center visible"
                    role = 'button'
                    id="button-next" 
                    onClick={loungeRef?.slickNext}>
                    <img src={nextArrow} 
                        alt = ''       
                    />
                </div> 
            </div>
        </div>
    );
}

export default Concerts;