import React from 'react'
import default_cover from '../images/default_cover.jpg'

export default function Slide(props) {

    
    function toTimeString(seconds) {
        let inTime = (new Date(seconds * 1000)).toUTCString().match(/(\d\d:\d\d:\d\d)/)[0];
        if(seconds < 3600){
            inTime = inTime.slice(3, 8)
        }
        return (inTime)
      }


    return (
        <div className='position-relative' title={props.tvData.title}>
            <img  src={props.type === 2 ? `https://img.cugate.com/?o=CUTV_VIDEO&i=${props.tvData.id}&s=300` : `https://img.cugate.com/?o=CUTV_PLAYLIST&i=${props.tvData.id}&s=300`} alt = ""
                onError = {({ currentTarget }) => {
                    currentTarget.onerror = null // prevents looping
                    currentTarget.src=default_cover
                }}
                style={
                    {
                        objectFit: 'cover',
                        borderRadius:'0.625vw',
                        width: `${props.width}`,
                        aspectRatio: "16/9",
                        cursor: 'pointer'
                    }
                }>
            </img>
            <div className='position-absolute text-white rounded px-1 bg-dark'
                style={{zIndex:"1000", bottom:"0.05rem", right:"0.2rem"}}>
                {toTimeString(props.tvData.duration)}
            </div>
        </div>
    )
}
