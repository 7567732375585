import React, { useEffect, useRef, useState } from 'react'
import SliderContent from './SliderContent'
import Arrow from './Arrow'
import useWindowDimentions from '../useWindowDimentions'
import nextArrow from '../icons/nextArrow.svg'
import prevArrow from '../icons/prevArrow.svg'
import { useSelector } from 'react-redux'

export default function Slider(props) {
  
  const [displayCount, setDisplayCount] = useState(props.num)
  let extraSlides = props.tvData.length - displayCount
  const [movedAndMove, setMovedAndMove] = useState([0, 0])
  const [arrows, setArrows] = useState(true)
  const [slideWrapperWidth, setSlideWrapperWidth] = useState(0)
  const [margin, setMargin] = useState(0)
  const sCWRef = useRef(null)

  const {width, height} = useWindowDimentions()

  const { active_pl_id } = useSelector(state => state.userPlaylists)

  useEffect(()=>{
    if(width < 650){
      setDisplayCount(2)
      setArrows(false)
      setSlideWrapperWidth(48.5)
      setMargin(0.5)
    } else if (width < 990){
      setDisplayCount(3)
      setArrows(false)
      setSlideWrapperWidth(30.5)
      setMargin(0.965)
    } else {
      setSlideWrapperWidth((width/displayCount)/width*100-0.6)
      setArrows(true)
      setDisplayCount(props.num)
      setMargin(0.5)
    }
  },[width])

  let slidePlusMargin = slideWrapperWidth + margin
  let sliderContentWidth=(slideWrapperWidth + margin) * props.tvData.length - margin
  let step=slidePlusMargin

  let isDown = false
  let startX

  const [startTouch, setStartTouch] = useState()
  const [endTouch, setEndTouch] = useState()
  const [touchDown, setTouchDown] = useState(false)

  let clickHoldTimer = null;

  const handleTouchStart = (e) => {
    setTouchDown(false)
    clickHoldTimer = setTimeout(() => {
      var touch = e.touches[0] || e.changedTouches[0];
      setStartTouch(touch.pageX)
      setTouchDown(true)
    }, 100)
  }

  const handleTouchEnd = (e) => {
    clearTimeout(clickHoldTimer);
    setTouchDown(false)
    setEndTouch()
    setStartTouch()
  }

  const handleTouchMove = (e) => {
    if (!touchDown) return
    var touch = e.touches[0] || e.changedTouches[0];
    setEndTouch(touch.pageX)
    let scroll = endTouch - startTouch
    if (scroll === 0) {
      return
    } else if (scroll > 1) {
      setTouchDown(false)
      prevSlide()
    } else if (scroll < 1) {
      setTouchDown(false)
      nextSlide()
    }
  }

  const handleSCWMouseDown = (e) => {
    e.preventDefault()
    isDown = true
    startX = e.clientX
  }

  const handleSCWMouseUp = (e) => {
    isDown = false
  }
  const handleSCWMouseLeave = (e) => {
    isDown = false
  }
  const handleSCWMouseMove = (e) => {
    e.preventDefault()
    if (!isDown) return
    let endX = e.clientX
    let scroll = endX - startX
    if (scroll === 0) {
      return
    } else if (scroll > 1) {
      isDown = false
      prevSlide()
    } else if (scroll < 1) {
      isDown = false
      nextSlide()
    }
  }


  useEffect(() => {
    const element = sCWRef.current
    element.addEventListener('mousedown', handleSCWMouseDown)
    element.addEventListener('mouseup', handleSCWMouseUp)
    element.addEventListener('mouseleave', handleSCWMouseLeave)
    element.addEventListener('mousemove', handleSCWMouseMove)
    return function cleanUp() {
      element.removeEventListener('mousedown', handleSCWMouseDown)
      element.removeEventListener('mouseup', handleSCWMouseUp)
      element.removeEventListener('mouseleave', handleSCWMouseLeave)
      element.removeEventListener('mousemove', handleSCWMouseMove)
    }
  })



  useEffect(() => {
    if(props.tvData.length < displayCount){
      setMovedAndMove([0, 0])
    } else {
      let active_pl_index = props.tvData.findIndex(p=>p.id===active_pl_id)
      if(active_pl_index!==-1){
        let index = Math.min(extraSlides, active_pl_index)
        setMovedAndMove([index, index*step])
      } else {
        setMovedAndMove([0, 0])
      }
    }
  }, [props.tvData])

  const prevSlide = () => {
    if (extraSlides > 0) {
      if (movedAndMove[0] === 0) {
      } else {
        setMovedAndMove([movedAndMove[0] - 1, movedAndMove[1] - step])
      }
    }
  }

  const nextSlide = () => {
    if (extraSlides > 0) {
      if (movedAndMove[0] === extraSlides) {
      } else {
        
        setMovedAndMove([movedAndMove[0] + 1, movedAndMove[1] + step])
      }
    }
  }

  return (
    <div className = 'col-12 d-flex flex-row justify-content-center align-items-center position-relative px-1 px-lg-0'
      >
        {
          arrows && props.tvData.length > displayCount ?
        <div 
          className= {movedAndMove[0] !== 0 ? 
            "position-absolute d-flex align-items-center justify-content-center visible":
            "position-absolute d-flex align-items-center justify-content-center hidden"
            } 
          id="button-prev"
          role = 'button'
          onClick={prevSlide}>
            <img src = {prevArrow} alt = ''
               />
          </div> : ""
        }
      <div>
        <div
          ref={sCWRef}
          className=''
          style={props.tvData.length < displayCount ? {
            width:`${slideWrapperWidth*props.tvData.length + (props.tvData.length-1)*margin}vw`,
            overflow: 'hidden',
          } : {
            width:`${slideWrapperWidth*displayCount + (displayCount-1)*margin}vw`,
            overflow: 'hidden',
          }}
          onTouchStart={handleTouchStart}
          onTouchEnd={handleTouchEnd}
          onTouchMove={handleTouchMove}
        >
          <SliderContent
            type = {props.type}
            showTitle = {props.showTitle}
            displayCount={displayCount}
            translate={movedAndMove[1]}
            sliderContentWidth={sliderContentWidth}
            slideWrapperWidth={slideWrapperWidth}
            tvData={props.tvData}
            slideClickHandler={props.slideClickHandler}
          />
        </div>
      </div>
      {
        arrows && props.tvData && props.tvData.length > displayCount ?
        <div  className= {movedAndMove[0] !== extraSlides ? 
          "position-absolute d-flex align-items-center justify-content-center visible" :
          "position-absolute d-flex align-items-center justify-content-center hidden"
          } 
          role = 'button'
          id="button-next" 
          onClick={nextSlide}>
          <img src={nextArrow}   
            alt = ''     
          />
        </div> : ""
        }
    </div>
  );
}