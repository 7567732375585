import React, {useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { listPlaylistVideos } from '../../actions/PlaylistActions'
import { useParams } from 'react-router-dom'
import arrowLeft from '../icons/Arrow_left.svg'
import { useNavigate } from 'react-router-dom';

const PlaylistPage = () => {

    const {playlist_id, playlist_title} = useParams()

    const dispatch = useDispatch()

    const navigate = useNavigate()
  
    const { loading, playlistVideos, active_pl_id} = useSelector(state => state.userPlaylists)
  
    useEffect(()=>{
  
      dispatch(listPlaylistVideos(playlist_id))
  
    },[dispatch, playlist_id])

    return (
        <div className = "w-100 min-vh-100 bg-bluedark d-flex flex-column align-items-center">
        <div className = "w-100 d-flex flex-column align-items-center pb-5">
        { 
            loading ? <div className = "text-info col-12 col-lg-10" >Loading...</div> :
            <>
                <div className = 'w-100 d-flex justify-content-center pt-5 pb-2'>
                    <div className = 'col-12 px-5 col-lg-10 d-flex'>
                        <img 
                            src = {arrowLeft} 
                            role = 'button'
                            onClick={()=>navigate(-1)}
                            alt = ''/>
                        <div className='ps-3 text-white fs-7 fw-bold'>{playlist_title}</div>
                    </div>
                </div>

                <div className='col-12 col-lg-10 px-4 d-flex flex-row flex-wrap'>
                    {
                        playlistVideos && playlistVideos.length ? 
                        playlistVideos.map((video)=>(
                            <div className='p-3 rounded'>
                                <img 
                                    src = {`https://img.cugate.com/?o=CUTV_VIDEO&i=${video.id}&s=300`} 
                                    alt = ''
                                    className='rounded'
                                    role = 'button'
                                    onClick={()=>navigate(`/home?v=${video.id}&p=${active_pl_id}&g=10`)}
                                />
                            </div>
                        )) : ""
                    }
                </div>
            </>
        }
        </div>  
        <div className='w-100 bg-primary' style={{height:'100px'}}></div>
        
</div>
    );
};

export default PlaylistPage;