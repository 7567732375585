import axios from 'axios'

import {
    GET_GENRES_REQUEST,
    GET_GENRES_SUCCESS,
    GET_GENRES_FAIL,

    GET_MOODS_REQUEST,
    GET_MOODS_SUCCESS,
    GET_MOODS_FAIL,

    GET_ARTISTS_REQUEST,
    GET_ARTISTS_SUCCESS,
    GET_ARTISTS_FAIL,

    GET_COMPOSERS_REQUEST,
    GET_COMPOSERS_SUCCESS,
    GET_COMPOSERS_FAIL,

    GET_TAGS_REQUEST,
    GET_TAGS_SUCCESS,
    GET_TAGS_FAIL,

    GET_VIDEOGENRES_REQUEST,
    GET_VIDEOGENRES_SUCCESS,
    GET_VIDEOGENRES_FAIL,

    FILTER_REQUEST,
    FILTER_SUCCESS,
    FILTER_FAIL,

    SEARCH_SUGGESTIONS_REQUEST,
    SEARCH_SUGGESTIONS_SUCCESS,
    SEARCH_SUGGESTIONS_FAIL,

    SEARCH_RESULTS_REQUEST,
    SEARCH_RESULTS_SUCCESS,
    SEARCH_RESULTS_FAIL,

    ACTIVE_GENRES_REQUEST,
    ACTIVE_GENRES_SUCCESS,
    ACTIVE_GENRES_FAIL,

    CATEGORIES_REQUEST,
    CATEGORIES_SUCCESS,
    CATEGORIES_FAIL,

    CATEGORY_VIDEOS_REQUEST,
    CATEGORY_VIDEOS_SUCCESS,
    CATEGORY_VIDEOS_FAIL,

    GET_ACTIVITIES_REQUEST,
    GET_ACTIVITIES_SUCCESS,
    GET_ACTIVITIES_FAIL,
} from '../constants/filterConstants'


export const getGenres = (components) => async (dispatch, getState) => {
    try {
        dispatch({
        type: GET_GENRES_REQUEST
        })
        
        const body = {data: components}

        const res = await axios.post(
            `${process.env.REACT_APP_API_URL}/cuviewTV/get_genres_for_filter/`, body
        )

        dispatch({
        type: GET_GENRES_SUCCESS,
        payload: res.data
        })

    } catch(error) {
        dispatch({
        type: GET_GENRES_FAIL,
        payload: error.response && error.response.data.message
        ? error.response.data.message
        : error.message
        })
    }
}

export const getMoods = (components) => async (dispatch, getState) => {

    try {
        dispatch({
        type: GET_MOODS_REQUEST
        })

        const body = {data: components}

        const res = await axios.post(
            `${process.env.REACT_APP_API_URL}/cuviewTV/get_moods_for_filter/`, body
        )

        dispatch({
        type: GET_MOODS_SUCCESS,
        payload: res.data
        })

    } catch(error) {
        dispatch({
        type: GET_MOODS_FAIL,
        payload: error.response && error.response.data.message
        ? error.response.data.message
        : error.message
        })
    }
}


export const getArtists = (keyword, components, page, index) => async (dispatch, getState) => {
    try {

        dispatch({
            type: GET_ARTISTS_REQUEST
            })

        const body = {data: components}

        const res = await axios.post(
            `${process.env.REACT_APP_API_URL}/cuviewTV/get_artists_for_filter/${keyword}/${page}/`, body
        )

        dispatch({
        type: GET_ARTISTS_SUCCESS,
        payload: res.data,
        index: index,
        page: page
        })

    } catch(error) {
        dispatch({
        type: GET_ARTISTS_FAIL,
        payload: error.response && error.response.data.message
        ? error.response.data.message
        : error.message
        })
    }
}

export const getComposers = (keyword, components, page, index) => async (dispatch, getState) => {
    try {

        dispatch({
            type: GET_COMPOSERS_REQUEST
            })

        const body = {data: components}

        const res = await axios.post(
            `${process.env.REACT_APP_API_URL}/cuviewTV/get_composers_for_filter/${keyword}/${page}/`, body
        )

        dispatch({
        type: GET_COMPOSERS_SUCCESS,
        payload: res.data,
        index: index,
        page: page
        })

    } catch(error) {
        dispatch({
        type: GET_COMPOSERS_FAIL,
        payload: error.response && error.response.data.message
        ? error.response.data.message
        : error.message
        })
    }
}

export const executeFilter = (components, page, index) => async (dispatch, getState) => {

    if(components && components.length){
        try {
            dispatch({
            type: FILTER_REQUEST,
            page: page, 
            index: index,
            })
    
            const body = {data: components}
    
            const res  = await axios.post(
                `${process.env.REACT_APP_API_URL}/cuviewTV/get_filter_results/${page}/`,
                body
            )
    
            dispatch({
            type: FILTER_SUCCESS,
            payload: res.data,
            page: page, 
            index: index
            })
    
        } catch(error) {
            dispatch({
            type: FILTER_FAIL,
            payload: error.response && error.response.data.message
            ? error.response.data.message
            : error.message
            })
        }
    }
}

export const getTags = (keyword, components, page, index) => async (dispatch, getState) => {
    try {

        dispatch({
            type: GET_TAGS_REQUEST
            })

        const body = {data: components}

        const res = await axios.post(
            `${process.env.REACT_APP_API_URL}/cuviewTV/get_tags_for_filter/${keyword}/${page}/`, body
        )

        dispatch({
        type: GET_TAGS_SUCCESS,
        payload: res.data,
        index: index,
        page: page
        })

    } catch(error) {
        dispatch({
        type: GET_TAGS_FAIL,
        payload: error.response && error.response.data.message
        ? error.response.data.message
        : error.message
        })
    }
}


export const getVideoGenres = (keyword, components, page, index) => async (dispatch, getState) => {
    try {

        dispatch({
            type: GET_VIDEOGENRES_REQUEST
            })

        const body = {data: components}

        const res = await axios.post(
            `${process.env.REACT_APP_API_URL}/cuviewTV/get_videogenres_for_filter/${keyword}/${page}/`, body
        )

        dispatch({
        type: GET_VIDEOGENRES_SUCCESS,
        payload: res.data,
        index: index,
        page: page
        })

    } catch(error) {
        dispatch({
        type: GET_VIDEOGENRES_FAIL,
        payload: error.response && error.response.data.message
        ? error.response.data.message
        : error.message
        })
    }
}


export const getSearchSuggestions = (keyword) => async (dispatch, getState) => {
    try {

        dispatch({
            type: SEARCH_SUGGESTIONS_REQUEST
        })

        const res = await axios.post(
            `${process.env.REACT_APP_API_URL}/cuviewTV/get_search_suggestions/${keyword}/`, {}
        )

        dispatch({
        type: SEARCH_SUGGESTIONS_SUCCESS,
        payload: res.data
        })

    } catch(error) {
        dispatch({
        type: SEARCH_SUGGESTIONS_FAIL,
        payload: error.response && error.response.data.message
        ? error.response.data.message
        : error.message
        })
    }
}


export const getSearchResults = (keyword, page, index) => async (dispatch, getState) => {
    try {
        dispatch({
        type: SEARCH_RESULTS_REQUEST,
        page: page, 
        index: index,
        })

        const res = await axios.post(
            `${process.env.REACT_APP_API_URL}/cuviewTV/get_search_results/${keyword}/${page}/`,{}
        )

        dispatch({
        type: SEARCH_RESULTS_SUCCESS,
        payload: res.data,
        })

    } catch(error) {
        dispatch({
        type: SEARCH_RESULTS_FAIL,
        payload: error.response && error.response.data.message
        ? error.response.data.message
        : error.message
        })
    }
}


export const getActiveGenres = () => async (dispatch, getState) => {

    try {
      dispatch({
        type: ACTIVE_GENRES_REQUEST
      })
  
        const { data } = await axios.get(
          `${process.env.REACT_APP_API_URL}/cuviewTV/get_active_genres/`
        )
  
      dispatch({
        type: ACTIVE_GENRES_SUCCESS,
        payload: data
      })
  
    } catch(error) {
      dispatch({
        type: ACTIVE_GENRES_FAIL,
        payload: error.response && error.response.data.message
        ? error.response.data.message
        : error.message
      })
    }
  }


  export const getCategories = () => async (dispatch, getState) => {

    try {
      dispatch({
        type: CATEGORIES_REQUEST
      })
  
        const { data } = await axios.get(
          `${process.env.REACT_APP_API_URL}/cuviewTV/get_categories/`
        )
  
      dispatch({
        type: CATEGORIES_SUCCESS,
        payload: data
      })
  
    } catch(error) {
      dispatch({
        type: CATEGORIES_FAIL,
        payload: error.response && error.response.data.message
        ? error.response.data.message
        : error.message
      })
    }
  }

  export const getCategoryVideos = (category_id, page, index) => async (dispatch, getState) => {

    try {
        dispatch({
          type: CATEGORY_VIDEOS_REQUEST,
          page: page,
          index:index
        })
    
          const { data } = await axios.get(
            `${process.env.REACT_APP_API_URL}/cuviewTV/get_category_videos/${category_id}/${page}/`
          )
    
        dispatch({
          type: CATEGORY_VIDEOS_SUCCESS,
          payload: data,
          page: page,
          index: index
        })
    
      } catch(error) {
        dispatch({
          type: CATEGORY_VIDEOS_FAIL,
          payload: error.response && error.response.data.message
          ? error.response.data.message
          : error.message
        })
      }
    }


    export const getActivities = (keyword, components, page, index) => async (dispatch, getState) => {
        try {
            dispatch({
            type: GET_ACTIVITIES_REQUEST
            })
            
            const body = {data: components}
    
            const res = await axios.post(
                `${process.env.REACT_APP_API_URL}/cuviewTV/get_activities/${keyword}/${page}/`, body
            )
    
            dispatch({
            type: GET_ACTIVITIES_SUCCESS,
            payload: res.data
            })
    
        } catch(error) {
            dispatch({
            type: GET_ACTIVITIES_FAIL,
            payload: error.response && error.response.data.message
            ? error.response.data.message
            : error.message
            })
        }
    }
    