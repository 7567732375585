import React, {useEffect, useState} from 'react';
import nextArrow from '../icons/nextArrow.svg'
import prevArrow from '../icons/prevArrow.svg'
import { getActiveGenres } from '../../actions/filterActions';
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom';
import Slider from '../slides/Slider';


function Genres(props) {

    const [genresRef, setGenresRef] = useState()

    const dispatch = useDispatch()

    const navigate = useNavigate()

    const { error, activeGenres} = useSelector(state => state.activeGenres)

    useEffect(()=>{

        dispatch(getActiveGenres())

    },[dispatch])

    const clickHandler = (item) => {

        if (props.user) {
            navigate(`/filter_results/${item.id}!1`)
        } else {
            navigate('/login')
        }

    }

    const settings = {
        dots: false,
        arrows: false,
        speed: 500,
        slidesToShow: 2, // Show multiple slides but without centering the first
        slidesToScroll: 1,
        inisialSlide: 1,
        mobileFirst:true,
        responsive: [
            {
              breakpoint: 0,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                initialSlide: 1,
              }
            },
            {
              breakpoint: 800,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                initialSlide: 1, 
              }
            },
            {
              breakpoint: 1120,
              settings: {
                slidesToShow: 5, // Show multiple slides but without centering the first
                slidesToScroll: 2,
                initialSlide: 0,
              }
            },
            {
                breakpoint: 1400,
                settings: {
                  slidesToShow: 7,
                  slidesToScroll: 2, 
                  initialSlide: 0,
                }
              },
              {
                breakpoint: 9000,
                settings: {
                  slidesToShow: 7,
                  slidesToScroll: 2, 
                  initialSlide: 0,
                }
              }
          ]
      };

    return (
        <div>
        <div className="col-12" style = {{marginTop:"2.5vw"}}>
            <div className="col-12 ps-5 pb-2 d-flex align-items-center" style = {{ fontSize:"1.25rem", color:'white'}}>
                <span className="text-white fw-bold fs-7 ps-4 pe-3">Genres</span>
            </div>
            <div className='position-relative'>
                <Slider
                        num = {7}
                        type = {3}
                        showTitle = {false}
                        tvData={activeGenres}
                        slideClickHandler={clickHandler}
                    /> : ""
            </div>
        </div>
        </div>
    );
}

export default Genres;