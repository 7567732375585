import React, {useEffect, useState, useRef} from 'react';
import { getActivities } from '../../actions/filterActions'
import { useDispatch, useSelector } from 'react-redux'
import down_arrow from '../icons/down_arrow.svg'
import { useNavigate } from 'react-router-dom';

const Activity = (props) => {

    const activityRef = useRef()

    const dispatch = useDispatch()

    const navigate = useNavigate()
  
    const {error, loading, activities} = useSelector(state => state.activities)

    const [activityActive, setActivityActive] = useState(false)

    const [page, setPage] = useState(1)

    const handleScroll = event => {
        const {scrollTop, clientHeight, scrollHeight} = event.currentTarget;
        let h
        h = Math.round(scrollHeight - scrollTop)
        if(h === clientHeight || h+1 === clientHeight || h-1 === clientHeight){
          setPage(prev => prev + 1)
        }
      }


    useEffect(()=>{
        dispatch(getActivities(" ", [], page, 1))
    }, [page])

    useEffect(()=>{
        document.addEventListener("mousedown", (event) => {
            if (activityRef == null || activityRef.current == null || !activityRef.current.contains(event.target)){
                setActivityActive(false)
            }
        })
    }) 


    const chooseHandler = (tag) => {
        if(props.user) {
            navigate(`/filter_results/${tag.id}!5`)
        } else {
            navigate(`/login`)
        }
    }

    return (
        <div className='col-6 d-flex flex-column'>
            <div className='w-100 h-100 bg-yellow d-flex align-items-center rounded px-2 px-lg-4 me-3' >
                <div 
                    className='d-flex w-100 justify-content-between align-items-center'
                    onClick={()=>setActivityActive(!activityActive)}>
                    <div className='fw-bold text-primary pe-2 pe-lg-5 b bottom_buttons'>Activity</div>
                    <img src = {down_arrow} role = 'button' alt = ''/>
                </div>
            </div>
            {
                    activityActive ?
                    <div className = "col-12 position-relative d-flex justify-content-end" 
                        style={{userSelect:"none"}} ref = {activityRef}>
                        <div className = "w-100 position-absolute mt-1 text-dark p-2 bg-white shadow rounded" 
                            style = {{zIndex:1000}}>
                            <div className='addToplaylist-list-wrapper'
                                onScroll = {handleScroll}
                                style={{maxHeight:"15rem"}}
                            >
                                {
                                loading ? <>Loading...</> :
                                activities && activities.length ? activities.map((item, index) => (
                                        <div
                                            className = "px-1 py-2 text-dark addSearchDropdown-item"
                                            role = "button"
                                            style={{transition:"all 0.2s", wordBreak:"normal", lineHeight:"1.125rem"}}
                                            key = {index}
                                            onClick = {()=>chooseHandler(item)}
                                        >{item.title.toLowerCase()}</div>
                                    )) : <div className='text-dark'>No result</div>
                                }
                            </div>
                        </div>
                    </div> : "" 
                }
        </div>
    );
};

export default Activity;