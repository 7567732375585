import React from 'react';

const GroupHeader = (props) => {
    
    return (
        <div className = 'w-100 my-3 d-flex flex-row align-items-center justify-content-center flex-wrap'
        style={{gap:"0.5rem"}}>
            {
                props.groups ?
                props.groups.map(group =>
                    <div key = {group.id}
                        className = {group.id === props.activeGroup.id ? 
                            'd-flex align-items-center p-3 my-0 my-lg-1 bg-info text-primary rounded groups-components' : 
                            'd-flex align-items-center p-3 my-0 my-lg-1 bg-primary rounded text-info groups-components'}
                            role = "button"
                        onClick={()=>{
                            props.setActiveGroup(group); 
                            props.setSearchParams({ ...Object.fromEntries([...props.searchParams]), g:group.id})
                            localStorage.setItem('group_id', group.id)}}>
                        {group.title}
                    </div>
                ) : ''
            }
        </div>
    );
};

export default GroupHeader;