import React, {useEffect, useState} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getCategories, getCategoryVideos } from '../../actions/filterActions';
import Slider from 'react-slick';
import ArrowRight from '../icons/Arrow_right.svg'
import arrowLeft from '../icons/Arrow_left.svg'
import { useNavigate } from 'react-router-dom';

const Categories = () => {

    const dispatch = useDispatch()

    const navigate = useNavigate()

    const [categoryRef, setCategoryRef] = useState()

    const { user, userLoading} = useSelector(state => state.user)

    const {categories} = useSelector(state=>state.categories)

    useEffect(()=>{
        dispatch(getCategories())
    },[dispatch])


    const clickHandler = (tag) => {

        if (user) {
            navigate(`/category/${tag.title}/${tag.id}`)
        } else {
            navigate('/login')
        }

    }

    const settings = {
        className: "slider variable-width",
        arrows: false,
        infinite: true,
        centerMode: true,
        slidesToShow: 1,
        slidesToScroll: 3,
        variableWidth: true,
      };


    return (
        <div className='col-12 position-relative d-flex justify-content-center align-items-center'>
            <div 
                className= "d-flex align-items-center position-absolute justify-content-end visible"
                id="button-prev"
                role = 'button'
                style={{left:'2vw'}}
                onClick={categoryRef?.slickPrev}>
                    <img src = {arrowLeft} alt = ''
                    />
            </div>
            
            <div className = 'col-11 py-5 position-relative'>
                    {
                    <Slider {...settings} ref={setCategoryRef} slickGoTo>
                        { categories && categories.length ?
                            categories.map((tag, index) => (
                                <div
                                    key = {index}
                                    role = 'button'
                                    className='px-1' style={{width:`${tag.length*5}px`}}
                                    onClick={()=>clickHandler(tag)}
                                >
                                    <div
                                        className='bg-secondary rounded px-4 py-2 fs-3 text-info'
                                        style={{fontWeight:500}}
                                    >
                                        {tag.title.charAt(0).toUpperCase() + tag.title.slice(1)}
                                    </div>

                                </div>
                            )) : ''
                        }
                    </Slider>
                    }
            </div>
            <div  className="d-flex align-items-center justify-content-start position-absolute "
                role = 'button'
                id="button-next" 
                style={{right:'2vw'}}
                onClick={categoryRef?.slickNext}>
                <img src={ArrowRight} 
                    alt = ''       
                />
            </div> 
        </div>
    );
};

export default Categories;