import React, {useEffect, useState, useRef} from 'react';
import { getTags } from '../../actions/filterActions'
import { useDispatch, useSelector } from 'react-redux'
import FilterComponent from './FilterComponent';
import AdminFilterComponent from '../../adminContent/AdminVideos/AdminFilterComponent';

const Tags = (props) => {

    const searchTagRef = useRef()

    const dispatch = useDispatch()

    const tagList = useSelector(state => state.tags)
  
    const {error, loading, tags} = tagList

    const [tagActive, setTagActive] = useState(false)

    const [tagValue, setTagValue] = useState("")

    const [page, setPage] = useState(1)


    const handleScroll = event => {
        const {scrollTop, clientHeight, scrollHeight} = event.currentTarget;
        let h
        h = Math.round(scrollHeight - scrollTop)
        if(h === clientHeight || h+1 === clientHeight || h-1 === clientHeight){
          setPage(prev => prev + 1)
        }
      }

    useEffect(()=>{
        const identifer = setTimeout(()=>{
            if (tagValue){
                dispatch(getTags(tagValue, props.filterList, 1, 0))
            } else {
                dispatch(getTags(" ", props.filterList, 1, 0))
            }
        }, 500)  

        return () => { clearTimeout(identifer) }

    },[tagValue, props.filterList])

    useEffect(()=>{
        if (tagValue){
            dispatch(getTags(tagValue, props.filterList, page, 1))
        } else {
            dispatch(getTags(" ", props.filterList, page, 1))
        }
    }, [page])

    useEffect(()=>{
        document.addEventListener("mousedown", (event) => {
            if (searchTagRef == null || searchTagRef.current == null || !searchTagRef.current.contains(event.target)){
            setTagActive(false)
            }
        })
    }) 

    const chooseHandler = (tag) => {
        if (props.filterList.filter(g => g[0] === tag.id && g[2] === 5 ).length === 0 ){
            props.setFilterList((prevArr)=>{ return[...prevArr, [tag.id, tag.title, 5]]})
        }
        setTagActive(false)
        setTagValue("")
    }


    const handleKeyDown = (event) => {
        if (event.key === "Enter") {
            if (tags && tags.length){
                if (props.filterList.filter(g => g[0] === tags[0].id && g[2] === 5 ).length === 0 ){
                    props.setFilterList((prevArr)=>{ return[...prevArr, [tags[0].id, tags[0].title, 5]]})
                }
                setTagActive(false)
                setTagValue('')
            }
        }
    }


    return (
        <>
            {
                props.index === 1 ? 
                <AdminFilterComponent
                    data = {tags}
                    setValue = {setTagValue}
                    setActive = {setTagActive}
                    value = {tagValue}
                    isActive = {tagActive}
                    chooseHandler = {chooseHandler}
                    componentRef = {searchTagRef}
                    handleScroll = {handleScroll}
                    title = "Tags"
                    loading = {loading}
                /> :
                <FilterComponent
                    data = {tags}
                    setValue = {setTagValue}
                    setActive = {setTagActive}
                    value = {tagValue}
                    isActive = {tagActive}
                    chooseHandler = {chooseHandler}
                    componentRef = {searchTagRef}
                    handleScroll = {handleScroll}
                    title = "Tags"
                    loading = {loading}
                    handleKeyDown = {handleKeyDown}
            />
            }
        </>
    );
};

export default Tags;