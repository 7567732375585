import React, {useState, useRef, useEffect} from 'react';
import { getComposers } from '../../actions/filterActions'
import { useDispatch, useSelector } from 'react-redux'
import FilterComponent from './FilterComponent';
import AdminFilterComponent from '../../adminContent/AdminVideos/AdminFilterComponent';

const Composers = (props) => {

    const searchComposerRef = useRef()

    const dispatch = useDispatch()

    const composerList = useSelector(state => state.composers)
  
    const {error, loading, composers} = composerList

    const [composerActive, setComposerActive] = useState(false)

    const [composerValue, setComposerValue] = useState("")

    const [page, setPage] = useState(1)

    const handleScroll = event => {
        const {scrollTop, clientHeight, scrollHeight} = event.currentTarget;
        let h
        h = Math.round(scrollHeight - scrollTop)
        if(h === clientHeight || h+1 === clientHeight || h-1 === clientHeight){
          setPage(prev => prev + 1)
        }
      }

    useEffect(()=>{
        document.addEventListener("mousedown", (event) => {
            if (searchComposerRef == null || searchComposerRef.current == null || !searchComposerRef.current.contains(event.target)){
            setComposerActive(false)
            }
        })
    }) 

    useEffect(()=>{
        const identifer = setTimeout(()=>{
            if (composerValue){
                dispatch(getComposers(composerValue, props.filterList, 1, 0))
            } else {
                dispatch(getComposers(" ", props.filterList, 1, 0))
            }
        }, 500)  

        return () => { clearTimeout(identifer) }

    },[composerValue, props.filterList])


    useEffect(()=>{
        if (composerValue){
            dispatch(getComposers(composerValue, props.filterList, page, 1))
        } else {
            dispatch(getComposers(" ", props.filterList, page, 1))
        }
    }, [page])

    const chooseHandler = (composer) => {
        if (props.filterList.filter(g => g[0] === composer.id && g[2] === 4 ).length === 0 ){
            props.setFilterList((prevArr)=>{ return[...prevArr, [composer.id, composer.title, 4]]})
        }
        setComposerActive(false)
        setComposerValue('')
    }
    
    const handleKeyDown = (event) => {
        if (event.key === "Enter") {
            if (composers && composers.length){
                if (props.filterList.filter(g => g[0] === composers[0].id && g[2] === 4 ).length === 0 ){
                    props.setFilterList((prevArr)=>{ return[...prevArr, [composers[0].id, composers[0].title, 4]]})
                }
                setComposerActive(false)
                setComposerValue('')
            }
        }
    }


    return (
        <>
            {
                props.index === 1 ? 
                <AdminFilterComponent
                    data = {composers}
                    setValue = {setComposerValue}
                    setActive = {setComposerActive}
                    value = {composerValue}
                    isActive = {composerActive}
                    chooseHandler = {chooseHandler}
                    componentRef = {searchComposerRef}
                    handleScroll = {handleScroll}
                    title = "Composers"
                    loading = {loading}
                /> :
                <FilterComponent
                    data = {composers}
                    setValue = {setComposerValue}
                    setActive = {setComposerActive}
                    value = {composerValue}
                    isActive = {composerActive}
                    chooseHandler = {chooseHandler}
                    componentRef = {searchComposerRef}
                    handleScroll = {handleScroll}
                    title = "Composers"
                    handleKeyDown = {handleKeyDown}
                    loading = {loading}
                />
            }
        </>
    );
};

export default Composers;