import React from 'react';
import plusicon from '../icons/Plus_1.svg'
import { useNavigate } from 'react-router-dom';


function SingleSlide(props) {

    const navigate = useNavigate()

    return (
        <>
        <div className='w-100' style = {{cursor:"pointer", perspective:'100px'}}         
            onClick = {()=>{props.itemClickHandler(props.item)}} >
          <div 
                className='px-2 w-100 slider_image '
                onMouseEnter={()=>props.setActiveSlide(props.index)}>
                <img src = {`https://img.cugate.com/?o=CUTV_PLAYLIST&i=${props.item.id}&s=300`}
                    alt = "aaaaaa" 
                    className='rounded w-100 '
                    onError = {({ currentTarget }) => {
                        currentTarget.onerror = null // prevents looping
                        // currentTarget.src=Default
                        }}    
                />
            </div>
            {/* <div classname = 'border border-light border-rounded w-100' 
                style = {{cursor:"pointer", perspective:'100px'}} onMouseEnter={()=>props.setActiveSlide(props.index)}>

                <img src = {`https://img.cugate.com/?o=CUTV_PLAYLIST&i=${props.item.id+1}&s=300`}
                    alt = "aaaaaa" 
                    className='rounded-top'
                    onError = {({ currentTarget }) => {
                        currentTarget.onerror = null // prevents looping
                        // currentTarget.src=Default
                        }}    
                />
                {
                    props.activeSlide === props.index &&
                    <div className='px-2'>
                        <div className = 'd-flex justify-content-between'>
                            <div className='text-white fs-3' style={{fontWeight:500}}>
                                {props.item.title.toUpperCase()}
                            </div>
                            <div className = 'text-info'>17 Videos</div>
                        </div>
                        <div className = 'd-flex'>
                            <div className='bg-yellow rounded px-3 py-1'>More</div>
                            <img src = {plusicon} alt = ""/>
                        </div>
                    </div>
                }

                
            </div>  */}
        </div>
        </>
    );
}

export default SingleSlide;