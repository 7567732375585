import React, {useRef, useEffect} from 'react'
import ReactPlayer from 'react-player/lazy'
import { getActiveVideos } from '../../actions/videoActions';
import { useDispatch, useSelector } from 'react-redux'


function Banner(props) {

    const dispatch = useDispatch()

    const {activeVideo} = useSelector(state => state.activeVideo)  

    useEffect(()=>{
        dispatch(getActiveVideos(194))
    },[dispatch])

    const bannerPlayerRef = useRef()

    return (
        <div id="banner_wrapper">
            {
                activeVideo &&

                <ReactPlayer
                    ref = {bannerPlayerRef} 
                    className='react-player shrinked'    
                    url = {activeVideo.scripted_bunny_path}
                    playing = {true}
                    muted = {true}
                    config={{
                    file: { 
                        attributes: {
                        controlsList: 'nodownload noprops.fullscreen'
                        }
                    }
                    }}
                    width="100%"
                    height='100%'
                />  
            }

        </div>
    );
}

export default Banner;