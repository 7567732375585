import React, {useEffect, useState, useRef} from 'react';
import { getMoods } from '../../actions/filterActions'
import { useDispatch, useSelector } from 'react-redux'
import down_arrow from '../icons/down_arrow.svg'
import { useNavigate } from 'react-router-dom';

const Mood = (props) => {   

    const moodRef = useRef()

    const dispatch = useDispatch()
  
    const {error, loading, moods} = useSelector(state => state.moods)

    const [moodActive, setMoodActive] = useState(false)

    const navigate = useNavigate()


    useEffect(()=>{
 
        dispatch(getMoods([]))
    
      },[])


    const clickHandler = (item) => {
        if (props.user){
            navigate(`/filter_results/${item.id}!2`)
        } else {
            navigate('/login')
        }
    }


    return (
        <div className='col-6 d-flex flex-column'>
            <div className='w-100 h-100 bg-yellow d-flex py-2 align-items-center rounded px-2 px-lg-4 me-3' >
                <div 
                    className='d-flex w-100 align-items-center justify-content-between'
                    onClick={()=>setMoodActive(!moodActive)}>
                    <div className='fw-bold text-primary pe-2 pe-lg-5 b bottom_buttons'>Mood</div>
                    <img src = {down_arrow} role = 'button' alt = ''/>
                </div>
            </div>
            {
                    moodActive ?
                    <div className = "col-12 position-relative d-flex justify-content-end" 
                        style={{userSelect:"none"}} ref = {moodRef}>
                        <div className = "w-100 position-absolute mt-1 text-dark p-2 bg-white shadow rounded" 
                            style = {{zIndex:1000}}>
                            <div className='addToplaylist-list-wrapper'
                                style={{maxHeight:"15rem"}}
                            >
                                {
                                loading ? <>Loading...</> :
                                moods && moods.length ? moods.map((item, index) => (
                                    <div
                                        className = "px-1 py-2 text-dark addSearchDropdown-item"
                                        role = "button"
                                        onClick = {()=>{clickHandler(item)}}
                                        style={{transition:"all 0.2s", wordBreak:"normal", lineHeight:"1.125rem"}}
                                        key = {index}
                                    >{item.title.toLowerCase()}</div>
                                    )) : <div className='text-dark'>No result</div>
                                }
                            </div>
                        </div>
                    </div> : "" 
                }
        </div>
    );
};

export default Mood;