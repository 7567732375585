import React, {useState} from 'react'
import Slider from '../slides/Slider'
import { useSelector } from 'react-redux'
import ArrowRight from '../icons/Arrow_right.svg'



const Playlists = (props) => {

  const [message, setMessage] = useState()


  const userPlaylistsList = useSelector(state => state.userPlaylists)

  const {error, userPlaylistloading, userPlaylists } = userPlaylistsList

  return (
    <>
      {
      userPlaylists && userPlaylists.length ?
        <div className='container-fluid bg-bluedark pb-4' ref = {props.myPlaylistsRef}>
          {
            userPlaylistloading ? <div>Loading...</div> :
            message ? <h3>{message}</h3> :
            <div className = 'col-12 d-flex flex-column justify-content-center align-items-center'>
              <div className = "w-100 px-1 px-lg-0 py-3">
                <div className="col-12 ps-5 d-flex align-items-center" style = {{ fontSize:"1.25rem", color:'white'}}>
                    <span className="text-white fw-bold fs-7 ps-4 pe-3">My Playlists</span>
                </div>
                  </div>
                    <Slider
                      num = {5}
                      type = {1}
                      tvData={userPlaylists}
                      slideClickHandler = {props.slideClickHandler}
                  />
                </div>
          }
        </div> : "" 
      }
    </>
  )
}

export default Playlists;

