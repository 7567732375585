import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import Slider from '../slides/Slider';
import { getGroupPlaylists } from '../../actions/PlaylistActions'

export default function PlaylistGroup(props) {

  const dispatch = useDispatch()

  const playlistList = useSelector(state => state.groupPlaylists)
  const { error, groupPlaylists} = playlistList

  useEffect(()=>{

    dispatch(getGroupPlaylists(props.activeGroup.id))

  },[dispatch, props.activeGroup])


  return (
    <div className='col-12 d-flex flex-column justify-content-center'>
          {
            error ? <h3>{error}</h3> :
            groupPlaylists && groupPlaylists.length ?
            <>
              <Slider
                  num = {5}
                  type = {1}
                  showTitle = {false}
                  tvData={groupPlaylists}
                  slideClickHandler={props.slideClickHandler}
              />
            </> :""
          }
    </div>
  )
}

